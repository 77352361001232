/**
 * @module jqCustom
 */
(function($) {

    'use strict';

    if (typeof jqCustom === 'undefined') {
        return null;
    }

    /**
     * Example module
     *
     * @class jqCustom.StickyButtons
     */
    var StickyButtons = (function() {

        /**
         * Reference on the container
         *
         * @private
         * @property container
         * @type {Object}
         */
        var container,
        /**
         * Reference on phone number link
         *
         * @private
         * @property phoneNumber
         * @type {Object}
         */
            phoneNumber,
        /**
         * event type
         *
         * @private
         * @property evtType
         * @type {String}
         */
            evtType = window.touch ? 'touchstart' : 'click';             

        /**
         * Initialization of the module
         *
         * @method init
         * @example
         *
         *      <div id="example-container">...</div>
         */
         function init() {
             container = $('.sticky-buttons');
             if (container.length) {
                 initListener();
             }
         }

         /**
          * Register Listener
          *
          * @private
          * @method initListener
          */
         function initListener() {
             container.addClass('animate');
             phoneNumber = container.find('.sticky-buttons-phone--number');

             phoneNumber.on(evtType, function() {
                $(this).toggleClass('is-expanded');
             });

             $(window).on('resize wheel scroll', function(event){
                phoneNumber.removeClass('is-expanded');
             });
         }

        /**
         * @return Public-API
         */
        return {
            init: init
        };

    }());

    /**
     *
     * @for jqCustom
     * @property StickyButtons
     * @type {Function}
     */
    jqCustom.StickyButtons = StickyButtons;

    return StickyButtons;

}(jQuery));
