/**
 * @module jqCustom
 */
(function($) {

    'use strict';

    if (typeof jqCustom === 'undefined') {
        return null;
    }

    /**
     * Example module
     *
     * @class jqCustom.ServiceNavigation
     */
    var ServiceNavigation = (function() {

        /**
         * Reference on the container
         *
         * @private
         * @property container
         * @type {Object}
         */
        var container,
        /**
         * Reference on language switch link
         *
         * @private
         * @property languageSwitch
         * @type {Object}
         */
            languageSwitch,
        /**
         * Reference on close service navigation button
         *
         * @private
         * @property closeNavBtn
         * @type {Object}
         */
            closeNavBtn,
        /**
         * event type
         *
         * @private
         * @property evtType
         * @type {String}
         */
            evtType = window.touch ? 'touchstart' : 'click';

        /**
         * Initialization of the module
         *
         * @method init
         */
         function init() {
             container = $('.service-nav');
             if (container.length) {
                 initListener();
             }
         }

         /**
          * Register Listener
          *
          * @private
          * @method initListener
          */
         function initListener() {
             container.addClass('animate');

             languageSwitch = container.find('.login-switch');
             closeNavBtn = container.find('.close-nav-btn');

             languageSwitch.on(evtType, function() {
                $(this).closest('.login').toggleClass('is-visible');
                $(this).closest(container).toggleClass('is-expanded');
             });

             closeNavBtn.on(evtType, function() {
                $(this).closest(container).find('.login').removeClass('is-visible');
                $(this).closest(container).removeClass('is-expanded');
             });
         }

        /**
         * @return Public-API
         */
        return {
            init: init
        };

    }());

    /**
     *
     * @for jqCustom
     * @property ServiceNavigation
     * @type {Function}
     */
    jqCustom.ServiceNavigation = ServiceNavigation;

    return ServiceNavigation;

}(jQuery));
