/**
 * @module jqCustom
 */
(function($) {

    'use strict';

    if (typeof jqCustom === 'undefined') {
        return null;
    }

    /**
     * Example module
     *
     * @class jqCustom.Example
     */
    var Preview = (function() {

        /**
         * Reference on the container
         *
         * @private
         * @property container
         * @type {Object}
         */
        var container,

        /**
         * Reference on the container height
         *
         * @private
         * @property containerHeight
         * @type {number}
         */
        containerHeight,

        /**
         * Reference on the container top position
         *
         * @private
         * @property containerTopPos
         * @type {Number}
         */
        containerTopPos,

        /**
         * Reference on the amout of children inside of container
         *
         * @private
         * @property innerContainerLength
         * @type {Number}
         */
        innerContainerLength,

        /**
         * Reference on the scrollspacer
         *
         * @private
         * @property scrollspacer
         * @type {Object}
         */
        scrollspacer = $('.scrollspacer-preview'),

        /**
         * Reference on the scrollspacer top pos
         *
         * @private
         * @property scrollspacerTopPos
         * @type {number}
         */
        scrollspacerTopPos,

        /**
         * Reference on the scrollspacer height
         *
         * @private
         * @property scrollspacerHeight
         * @type {number}
         */
        scrollspacerHeight,

        /**
         * Reference on the scroll delta after container is reached
         * @private
         * @property dy
         * @type {number}
         */
        dy,

        /**
         * Reference on the last scrollposition before container is reached
         * @private
         * @property dy
         * @type {number}
         */
        lastY;


        /**
         * Initialization of the module
         *
         * @method init
         * @example
         *
         *      <div id="example-container">...</div>
         */
        function init() {
            container = $('.preview');
            if (container.length) {
                container.addClass('animate');
                initListener();
                getContainerDimensions();
                handleScroll();
            }
        }



        /**
         * Register Listener
         *
         * @private
         * @method initListener
         */
         function initListener() {
             $(window)
                 .on('resizend load', getContainerDimensions)
                 .on('wheel scroll', function(event){
                     handleScroll(event);
                 })
                 .one('recalc', recalcNextmodule);

             window.setTimeout(getContainerDimensions,1000);
         }

         /**
         * Get Position of Container
         *
         * @private
         * @method getContainerOffset
         */
        function getContainerDimensions() {
            containerTopPos = container.offset().top;
            containerHeight = container.outerHeight(true);
            scrollspacerHeight = scrollspacer.outerHeight(true);
            scrollspacerTopPos = scrollspacer.offset().top;
            innerContainerLength=container.find('.slide').length;
        }

        function recalcNextmodule(){
            var modules = $('body').find('.recalc');
            var thisModuleIdx = modules.index(container);
            var nextJsModule = modules.eq(thisModuleIdx+1).data('module');
            if(nextJsModule) jqCustom[nextJsModule].recalc();
        }

        function recalc() {
            containerTopPos = container.offset().top;
            scrollspacerTopPos = scrollspacer.offset().top;
        }

        /**
        * Handle Scroll Event
        *
        * @private
        * @method handleScroll
        */
        var done=false;
        function handleScroll(event) {
            var doc = document.documentElement;
            var scrollY = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
            var endOfScrollConversion = scrollspacerTopPos+scrollspacerHeight-containerHeight;
            var scrollDelta = endOfScrollConversion - containerTopPos;

            if ((scrollY >= containerTopPos) && (scrollY <= endOfScrollConversion)) {
                dy = (scrollY-lastY);
                //container.find('.slide:nth-child(2)').addClass('visible');

                for(var i=2;i<innerContainerLength+1;i++){
                    if(dy >= scrollDelta/(innerContainerLength+1)*(i-1)){
                        container.find('.slide:nth-child('+i+')').addClass('visible');
                        if(i>3)container.find('.slide:nth-child('+(i-1)+')').removeClass('visible');
                    }else{
                        container.find('.slide:nth-child('+i+')').removeClass('visible');
                    }
                }

                //handleNextModule(containerHeight, scrollDelta, dy);

            } else if ( scrollY < containerTopPos ){
                container.find('.slide:nth-child(2)').removeClass('visible');
                lastY=scrollY;
            } else if( scrollY > containerTopPos ){

                if(!done) recalcNextmodule();
                done=true;
            }
        }


        function handleNextModule(containerHeight, scrollDelta, dy){
            var nextModule = container.closest('.container-fluid').next();
            var topPos = containerHeight;
            console.log(containerHeight, scrollDelta, dy, nextModule.height());
            if(scrollDelta-nextModule.height()>=dy){
                nextModule.css({
                    position: 'fixed',
                    top: topPos+'px'
                });
            }else{
                nextModule.css({
                    position: 'relative',
                    top: '0px'
                });
            }

        }

        /**
         * @return Public-API
         */
        return {
            init: init,
            recalc: recalc
        };

    }());

    /**
     *
     * @for jqCustom
     * @property Preview
     * @type {Function}
     */
    jqCustom.Preview = Preview;

    return Preview;

}(jQuery));
