/**
 * @module jqCustom
 */
(function ($) {

    'use strict';

    if (typeof jqCustom === 'undefined') {
        return null;
    }

    /**
     * ScreenSlider module
     *
     * @class jqCustom.ScreenSlider
     */
    var ScreenSlider = (function() {

            /**
             * Reference on the container
             *
             * @private
             * @property container
             * @type {Object}
             */
        var container;

        /**
         * Initialization of the module
         *
         * @method init
         */
        function init() {
            container = $('.screen-slider');
            if (container.length) {
                initListener();
            }
        }

        /**
         * Register Listener
         *
         * @private
         * @method initListener
         */
        function initListener() {
            container.each(function() {
                var slider = $(this);

                slider.slick({
                    autoplay: true,
                    autoplaySpeed: 5000,
                    responsive: [
                        {
                          breakpoint: 1199,
                          settings: {
                            arrows: false,
                            dots: true
                          }
                        }
                    ]
                });
            });
        }

        /**
         * @return Public-API
         */
        return {
            init : init
        };

    }());

    /**
     *
     * @for jqCustom
     * @property ScreenSlider
     * @type {Function}
     */
    jqCustom.ScreenSlider = ScreenSlider;

    return ScreenSlider;

}(jQuery));
