/**
 * @module jqCustom
 */
(function ($) {

    'use strict';

    if (typeof jqCustom === 'undefined') {
        return null;
    }

    /**
     * Example module
     *
     * @class jqCustom.Hyphens
     */
    var Hyphens = (function() {

            /**
             * Reference on the container
             *
             * @private
             * @property container
             * @type {Object}
             */
        var container;

        /**
         * Initialization of the module
         *
         * @method init
         * @example
         *
         *      <div id="example-container">...</div>
         */
        function init() {
            container = $('h1, h2, h3, h4, h1 *, h2 *, h3 *, h4 *, .video-title, .banner.icons strong');
            if (container.length) {
                initListener();
            }
        }

        /**
         * Register Listener
         *
         * @private
         * @method initListener
         */
        function initListener() {
            container.hyphenate('de');
        }

        /**
         * @return Public-API
         */
        return {
            init : init
        };

    }());

    /**
     *
     * @for jqCustom
     * @property Hyphens
     * @type {Function}
     */
    jqCustom.Hyphens = Hyphens;

    return Hyphens;

}(jQuery));
