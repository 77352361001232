/**
 * @module jqCustom
 */
(function($) {

    'use strict';

    if (typeof jqCustom === 'undefined') {
        return null;
    }

    /**
     * Example module
     *
     * @class jqCustom.Diagram
     */
    var Diagram = (function() {

        /**
         * Reference on the container
         *
         * @private
         * @property container
         * @type {Object}
         */
        var container;

        /**
         * Initialization of the module
         *
         * @method init
         * @example
         *
         *      <div id="example-container">...</div>
         */
        function init() {
            container = $('.diagram');
            if (container.length) {
                initListener();
            }
        }

        /**
         * Register Listener
         *
         * @private
         * @method initListener
         */
        function initListener() {
            var cnt = container.find('.single-frame').length;
            var i = 1;
            var ani = setInterval(function(){
                container.find('.single-frame:not(:nth-child('+i+'))').hide();
                container.find('.single-frame:nth-child('+i+')').show();
                if(i<cnt){
                    i++;
                }else{
                    i = 1;
                }
            },2500);
        }

        /**
         * @return Public-API
         */
        return {
            init: init
        };

    }());

    /**
     *
     * @for jqCustom
     * @property Diagram
     * @type {Function}
     */
    jqCustom.Diagram = Diagram;

    return Diagram;

}(jQuery));
