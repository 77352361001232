/**
 * @module jqCustom
 */
(function ($) {

    'use strict';

    if (typeof jqCustom === 'undefined') {
        return null;
    }

    /**
     * ScrollSmooth module
     *
     * @class jqCustom.ScrollSmooth
     */
    var ScrollSmooth = (function() {

        /**
         * event type
         *
         * @private
         * @property evtType
         * @type {String}
         */
        var evtType = window.touch ? 'touchstart' : 'click',

        /**
         * Reference on the anchor links
         *
         * @private
         * @property anchorLinks
         * @type {Object}
         */
        anchorLinks;

        /**
         * Initialization of the module
         *
         * @method init
         * @example
         *
         *      <div id="example-container">...</div>
         */
        function init() {
            anchorLinks = $('a[href^="#"]');

            if (anchorLinks.length) {
                initListener();
            }
        }

        /**
         * Register Listener
         *
         * @private
         * @method initListener
         */
        function initListener() {
            $(anchorLinks).on(evtType, function (event) {

                event.preventDefault();
                var hash = $(this).attr('href'),
                    hashOffset = $(hash).offset().top;

                $('html, body').animate({
                    scrollTop: hashOffset
                }, 5000);
            });
        }

        /**
         * @return Public-API
         */
        return {
            init : init
        };

    }());

    /**
     *
     * @for jqCustom
     * @property ScrollSmooth
     * @type {Function}
     */
    jqCustom.ScrollSmooth = ScrollSmooth;

    return ScrollSmooth;

}(jQuery));
