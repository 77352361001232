/**
 * @module jqCustom
 */
(function($) {

    'use strict';

    if (typeof jqCustom === 'undefined') {
        return null;
    }

    /**
     * Example module
     *
     * @class jqCustom.ScrollSlider
     */
    var ScrollSlider = (function() {

        /**
         * Reference on the container
         *
         * @private
         * @property container
         * @type {Object}
         */
        var container,

        /**
         * Reference on the container height
         *
         * @private
         * @property containerHeight
         * @type {number}
         */
        containerHeight,

        /**
         * Reference on the container top position
         *
         * @private
         * @property containerTopPos
         * @type {Number}
         */
        containerTopPos,

        /**
         * Reference on the container width
         *
         * @private
         * @property containerWidth
         * @type {Number}
         */
        containerWidth,

        /**
         * Reference on the scrollspacer
         *
         * @private
         * @property scrollspacer
         * @type {Object}
         */
        scrollspacer = $('.scrollspacer'),

        /**
         * Reference on the scrollspacer top pos
         *
         * @private
         * @property scrollspacerTopPos
         * @type {number}
         */
        scrollspacerTopPos,

        /**
         * Reference on the scrollspacer height
         *
         * @private
         * @property scrollspacerHeight
         * @type {number}
         */
        scrollspacerHeight,

        /**
         * Reference on the scroll delta after container is reached
         * @private
         * @property dy
         * @type {number}
         */
        dy,

        /**
         * Reference on the last scrollposition before container is reached
         * @private
         * @property dy
         * @type {number}
         */
        lastY,

        /**
         * Reference on the endposition before container is leaved
         * @private
         * @property dy
         * @type {number}
         */
        endPos = 0;

        /**
         * Initialization of the module
         *
         * @method init
         * @example
         *
         *      <div id="example-container">...</div>
         */
        function init() {
            container = $('.slider');
            if (container.length) {
                initListener();
                getContainerDimensions();
                handleScroll();
            }
        }

        /**
         * Register Listener
         *
         * @private
         * @method initListener
         */
         function initListener() {
             $(window)
                 .on('resizeend load', getContainerDimensions)
                 .on('wheel scroll', function(event){
                     handleScroll(event);
                 })
                 .one('recalc', recalcNextmodule);

             window.setTimeout(getContainerDimensions,1000);
         }

         /**
         * Get Position of Container
         *
         * @private
         * @method getContainerOffset
         */
         function getContainerDimensions() {
             containerTopPos = container.offset().top;
             containerWidth = 0;
             container.find('.inner section').each(function(){
                 containerWidth = containerWidth + $(this).outerWidth(true);
             });
             containerHeight = container.outerHeight(true);
             scrollspacerHeight = scrollspacer.outerHeight(true);
             scrollspacerTopPos = scrollspacer.offset().top;
         }

         function recalcNextmodule(){
             var modules = $('body').find('.recalc');
             var thisModuleIdx = modules.index(container);
             var nextJsModule = modules.eq(thisModuleIdx+1).data('module');
             if(nextJsModule) jqCustom[nextJsModule].recalc();
         }

         function recalc(){
             containerTopPos = container.offset().top;
             scrollspacerTopPos = scrollspacer.offset().top;
         }


        /**
         * Handle Scroll Event
         *
         * @private
         * @method handleScroll
         */
         var done=false;
         function handleScroll(event) {
             var doc = document.documentElement;
             var scrollY = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
             var ww = $(window).width();
             var scrollHeight = containerHeight+scrollspacerHeight-containerHeight;
             var endOfScrollConversion = scrollspacerTopPos+scrollspacerHeight-containerHeight;

             if ((scrollY >= containerTopPos) && (scrollY <= endOfScrollConversion)) {
                 dy = Math.floor((scrollY-lastY) * ((containerWidth-ww)/scrollHeight));

                 container.find('.inner').css({
                     'left':'-'+dy+'px'
                 });

                 //container.find('.inner').stop().animate({right:'-'+dy+'px'},1);

                 endPos = (endOfScrollConversion - containerTopPos) * ((containerWidth-ww)/scrollHeight);
             } else if ( scrollY < containerTopPos ){
                 lastY=scrollY;
                 container.find('.inner').css({
                     'left':0
                 });
             } else if ( scrollY > containerTopPos ){
                 container.find('.inner').css({
                     'left':'-'+endPos+'px'
                 });

                 if(!done) recalcNextmodule();
                 done=true;
             }
         }

        /**
         * @return Public-API
         */
        return {
            init: init,
            recalc: recalc
        };

    }());

    /**
     *
     * @for jqCustom
     * @property ScrollSlider
     * @type {Function}
     */
    jqCustom.ScrollSlider = ScrollSlider;

    return ScrollSlider;

}(jQuery));
